import * as Colors from './styles/colors';

export { Colors };

export { useTheme, withTheme, ThemeProvider } from './core/theming';

export { default as Provider } from './core/Provider';
export { default as DefaultTheme } from './styles/DefaultTheme';
export { default as DarkTheme } from './styles/DarkTheme';
export { default as shadow } from './styles/shadow';
export { default as overlay } from './styles/overlay';
export { default as configureFonts } from './styles/fonts';

import * as Avatar from './components/Avatar/Avatar';
import * as List from './components/List/List';
import * as Drawer from './components/Drawer/Drawer';

export { Avatar, List, Drawer };

export * from './components/FAB/AnimatedFAB';

export { default as Badge } from './components/Badge';
export { default as ActivityIndicator } from './components/ActivityIndicator';
export { default as Banner } from './components/Banner';
export { default as BottomNavigation } from './components/BottomNavigation/BottomNavigation';
export { default as Button } from './components/Button';
export { default as Card } from './components/Card/Card';
export { default as Checkbox } from './components/Checkbox';
export { default as Chip } from './components/Chip';
export { default as DataTable } from './components/DataTable/DataTable';
export { default as Dialog } from './components/Dialog/Dialog';
export { default as Divider } from './components/Divider';
export { default as FAB } from './components/FAB';
export { default as AnimatedFAB } from './components/FAB/AnimatedFAB';
export { default as HelperText } from './components/HelperText';
export { default as IconButton } from './components/IconButton';
export { default as Menu } from './components/Menu/Menu';
export { default as Modal } from './components/Modal';
export { default as Portal } from './components/Portal/Portal';
export { default as ProgressBar } from './components/ProgressBar';
export { default as RadioButton } from './components/RadioButton';
export { default as Searchbar } from './components/Searchbar';
export { default as Snackbar } from './components/Snackbar';
export { default as Surface } from './components/Surface';
export { default as Switch } from './components/Switch';
export { default as Appbar } from './components/Appbar';
export { default as TouchableRipple } from './components/TouchableRipple/TouchableRipple';
export { default as TextInput } from './components/TextInput/TextInput';
export { default as ToggleButton } from './components/ToggleButton';

export { default as Caption } from './components/Typography/Caption';
export { default as Headline } from './components/Typography/Headline';
export { default as Paragraph } from './components/Typography/Paragraph';
export { default as Subheading } from './components/Typography/Subheading';
export { default as Title } from './components/Typography/Title';
export { default as Text } from './components/Typography/Text';

// Types
export type { Props as ActivityIndicatorProps } from './components/ActivityIndicator';
export type { Props as AnimatedFABProps } from './components/FAB/AnimatedFAB';
export type { Props as AppbarProps } from './components/Appbar/Appbar';
export type { Props as AppbarActionProps } from './components/Appbar/AppbarAction';
export type { Props as AppbarBackActionProps } from './components/Appbar/AppbarBackAction';
export type { Props as AppbarContentProps } from './components/Appbar/AppbarContent';
export type { Props as AppbarHeaderProps } from './components/Appbar/AppbarHeader';
export type { Props as AvatarIconProps } from './components/Avatar/AvatarIcon';
export type { Props as AvatarImageProps } from './components/Avatar/AvatarImage';
export type { Props as AvatarTextProps } from './components/Avatar/AvatarText';
export type { Props as BadgeProps } from './components/Badge';
export type { Props as BannerProps } from './components/Banner';
export type { Props as BottomNavigationProps } from './components/BottomNavigation/BottomNavigation';
export type { Props as ButtonProps } from './components/Button';
export type { Props as CardProps } from './components/Card/Card';
export type { Props as CardActionsProps } from './components/Card/CardActions';
export type { Props as CardContentProps } from './components/Card/CardContent';
export type { Props as CardCoverProps } from './components/Card/CardCover';
export type { Props as CardTitleProps } from './components/Card/CardTitle';
export type { Props as CheckboxProps } from './components/Checkbox/Checkbox';
export type { Props as CheckboxAndroidProps } from './components/Checkbox/CheckboxAndroid';
export type { Props as CheckboxIOSProps } from './components/Checkbox/CheckboxIOS';
export type { Props as CheckboxItemProps } from './components/Checkbox/CheckboxItem';
export type { Props as ChipProps } from './components/Chip';
export type { Props as DataTableProps } from './components/DataTable/DataTable';
export type { Props as DataTableCellProps } from './components/DataTable/DataTableCell';
export type { Props as DataTableHeaderProps } from './components/DataTable/DataTableHeader';
export type { Props as DataTablePaginationProps } from './components/DataTable/DataTablePagination';
export type { Props as DataTableRowProps } from './components/DataTable/DataTableRow';
export type { Props as DataTableTitleProps } from './components/DataTable/DataTableTitle';
export type { Props as DialogProps } from './components/Dialog/Dialog';
export type { Props as DialogActionsProps } from './components/Dialog/DialogActions';
export type { Props as DialogContentProps } from './components/Dialog/DialogContent';
export type { Props as DialogScrollAreaProps } from './components/Dialog/DialogScrollArea';
export type { Props as DialogTitleProps } from './components/Dialog/DialogTitle';
export type { Props as DividerProps } from './components/Divider';
export type { Props as DrawerItemProps } from './components/Drawer/DrawerItem';
export type { Props as DrawerSectionProps } from './components/Drawer/DrawerSection';
export type { Props as FABProps } from './components/FAB/FAB';
export type { Props as FABGroupProps } from './components/FAB/FABGroup';
export type { Props as HelperTextProps } from './components/HelperText';
export type { Props as IconButtonProps } from './components/IconButton';
export type { Props as ListAccordionProps } from './components/List/ListAccordion';
export type { Props as ListAccordionGroupProps } from './components/List/ListAccordionGroup';
export type { Props as ListIconProps } from './components/List/ListIcon';
export type { Props as ListItemProps } from './components/List/ListItem';
export type { Props as ListSectionProps } from './components/List/ListSection';
export type { Props as ListSubheaderProps } from './components/List/ListSubheader';
export type { Props as MenuProps } from './components/Menu/Menu';
export type { Props as MenuItemProps } from './components/Menu/MenuItem';
export type { Props as ModalProps } from './components/Modal';
export type { Props as PortalProps } from './components/Portal/Portal';
export type { Props as PortalHostProps } from './components/Portal/PortalHost';
export type { Props as ProgressBarProps } from './components/ProgressBar';
export type { Props as ProviderProps } from './core/Provider';
export type { Props as RadioButtonProps } from './components/RadioButton/RadioButton';
export type { Props as RadioButtonAndroidProps } from './components/RadioButton/RadioButtonAndroid';
export type { Props as RadioButtonGroupProps } from './components/RadioButton/RadioButtonGroup';
export type { Props as RadioButtonIOSProps } from './components/RadioButton/RadioButtonIOS';
export type { Props as RadioButtonItemProps } from './components/RadioButton/RadioButtonItem';
export type { Props as SearchbarProps } from './components/Searchbar';
export type { Props as SnackbarProps } from './components/Snackbar';
export type { Props as SurfaceProps } from './components/Surface';
export type { Props as SwitchProps } from './components/Switch';
export type { Props as TextInputProps } from './components/TextInput/TextInput';
export type { Props as TextInputAffixProps } from './components/TextInput/Adornment/TextInputAffix';
export type { Props as TextInputIconProps } from './components/TextInput/Adornment/TextInputIcon';
export type { Props as ToggleButtonProps } from './components/ToggleButton/ToggleButton';
export type { Props as ToggleButtonGroupProps } from './components/ToggleButton/ToggleButtonGroup';
export type { Props as ToggleButtonRowProps } from './components/ToggleButton/ToggleButtonRow';
export type { Props as TouchableRippleProps } from './components/TouchableRipple/TouchableRipple';
export type { Props as CaptionProps } from './components/Typography/Caption';
export type { Props as HeadlineProps } from './components/Typography/Headline';
export type { Props as ParagraphProps } from './components/Typography/Paragraph';
export type { Props as SubheadingProps } from './components/Typography/Subheading';
export type { Props as TitleProps } from './components/Typography/Title';
export type { Props as TextProps } from './components/Typography/Text';

export type { Theme } from './types';
